import React, { useState } from 'react';
import { ContactFormInputs } from '../Summary';
import styles from './ContactForm.module.scss';
import { Button } from '@mui/material';
import cx from 'classnames';
import successIcon from '../../../../asset/success.svg';
import failIcon from '../../../../asset/fail.svg';
import { ReactSVG } from 'react-svg';

type Props = {
  onSubmit: (inputs: ContactFormInputs) => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  onBackClick: () => void;
};

export const ContactForm = ({
  onSubmit,
  isLoading,
  isSuccess,
  isError,
  onBackClick,
}: Props) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [contactMethods, setContactMethods] = useState<string[]>([]);

  const [hasErrors, setHasErrors] = useState(false);

  const handleSubmit = () => {
    if (!name || !email || contactMethods.length === 0) {
      setHasErrors(true);

      return;
    }

    onSubmit({
      methods: contactMethods,
      email: email,
      name: name,
      phone: phone,
    });
  };

  const handleMethodClick = (method: string) => {
    setContactMethods((prev) => {
      if (prev.includes(method)) {
        return prev.filter((prevMethod) => prevMethod !== method);
      }

      return [...prev, method];
    });
  };

  return (
    <div className={styles.contactFormContainer}>
      {(isSuccess || isError) && (
        <>
          <ReactSVG
            src={isSuccess ? successIcon : failIcon}
            className={styles.icon}
          />
          <div className={styles.actionTitle}>
            {isSuccess ? 'Thank you!' : 'Submission unsuccessful'}
          </div>
          <div className={styles.actionSubtitle}>
            {isSuccess
              ? 'Your form was submitted successfully'
              : 'Please check your details and try again.'}
          </div>
        </>
      )}
      {!isSuccess && !isError && (
        <>
          <input
            className={cx(styles.input, { [styles.error]: hasErrors && !name })}
            placeholder="Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className={cx(styles.input, {
              [styles.error]: hasErrors && !email,
            })}
            placeholder="Email*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className={styles.input}
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div
            className={cx(styles.checkboxesLabel, {
              [styles.errorLabel]: hasErrors && contactMethods.length === 0,
            })}
          >
            Preferred contact method*
          </div>
          <div className={styles.checkboxes}>
            <label
              className={styles.checkboxLabel}
              htmlFor="email"
              onChange={() => handleMethodClick('email')}
            >
              <input
                className={styles.checkbox}
                type={'checkbox'}
                id="email"
                checked={contactMethods.includes('email')}
              />
              <span>Email</span>
            </label>
            <label
              className={styles.checkboxLabel}
              htmlFor="phone"
              onChange={() => handleMethodClick('phone')}
            >
              <input
                className={styles.checkbox}
                type={'checkbox'}
                id="phone"
                checked={contactMethods.includes('phone')}
              />
              <span>Phone</span>
            </label>
            <label
              className={styles.checkboxLabel}
              htmlFor="sms"
              onChange={() => handleMethodClick('sms')}
            >
              <input
                className={styles.checkbox}
                type={'checkbox'}
                id="sms"
                checked={contactMethods.includes('sms')}
              />
              <span>SMS / WhatsApp</span>
            </label>
          </div>
        </>
      )}
      <div className={styles.submitButton}>
        {isSuccess && <Button onClick={() => onBackClick()}>Go Back</Button>}
        {isError && <Button onClick={handleSubmit}>Try Again</Button>}
        {!isSuccess && !isError && (
          <Button onClick={handleSubmit} disabled={isLoading}>
            Get a free quote
          </Button>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
