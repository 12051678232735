import React from 'react';
import { SelectedLocation as SelectedLocationType } from '../../Form/Form';
import styles from './SelectedLocation.module.scss';
import { ReactSVG } from 'react-svg';
import arrowRight from '../../../../asset/arrow-right.svg';

type Props = {
  selectedLocation: SelectedLocationType;
};

export const SelectedLocation = ({ selectedLocation }: Props) => {
  return (
    <div className={styles.selectedLocationContainer}>
      <div className={styles.date}>
        {selectedLocation.date.format('ddd, MMM D YYYY')}
      </div>
      <div className={styles.locationContainer}>
        <div className={styles.airportInfo}>
          <span className={styles.regionName}>
            {selectedLocation.from?.region}
          </span>
          <span className={styles.code}>
            {selectedLocation?.from?.iata ?? selectedLocation?.from?.icao}
          </span>
        </div>
        <ReactSVG src={arrowRight} className={styles.icon} />
        <div className={styles.airportInfo}>
          <span className={styles.regionName}>
            {selectedLocation.to?.region}
          </span>
          <span className={styles.code}>
            {selectedLocation?.to?.iata ?? selectedLocation?.to?.icao}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SelectedLocation;
