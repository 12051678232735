import React, { useEffect, useMemo, useState } from 'react';
import styles from './Form.module.scss';
import { Button } from '@mui/material';
import cx from 'classnames';
import LocationInputs from './LocationInputs/LocationInputs';
import DateInput from './DateInput/DateInput';
import PeopleInput from './PeopleInput/PeopleInput';
import moment from 'moment';
import { SelectedValues } from '../MainContainer';
import { Airport, ClassType, TripType } from '../../../constants/constants';

export type SelectedLocation = {
  from: Airport | null;
  to: Airport | null;
  date: moment.Moment;
};

type Props = {
  onSubmit: (submitInputs: SelectedValues) => void;
  airports: Airport[];
  selectedValues: SelectedValues | null;
};

export const Form = ({ onSubmit, airports, selectedValues }: Props) => {
  const [selectedTrip, setSelectedTrip] = useState<TripType>(
    selectedValues?.trip ?? TripType.ROUND,
  );
  const [selectedClass, setSelectedClass] = useState<ClassType>(
    selectedValues?.class ?? ClassType.BUSINESS,
  );
  const [selectedAdults, setSelectedAdults] = useState(
    selectedValues?.adults ?? 1,
  );
  const [selectedChildren, setSelectedChildren] = useState(
    selectedValues?.children ?? 0,
  );
  const [selectedInfants, setSelectedInfants] = useState(
    selectedValues?.infants ?? 0,
  );
  const [selectedLocations, setSelectedLocations] = useState<
    SelectedLocation[]
  >(
    selectedValues?.locations ?? [
      {
        from: null,
        to: null,
        date: moment(),
      },
    ],
  );
  const [displayErrors, setDisplayErrors] = useState(false);

  useEffect(() => {
    if (selectedTrip === TripType.ROUND) {
      setSelectedLocations((prev) =>
        [
          ...prev,
          {
            from: null,
            to: null,
            date: moment().add(1, 'days'),
          },
        ].slice(0, 2),
      );
      return;
    }

    if (selectedTrip === TripType.SINGLE) {
      setSelectedLocations((prev) =>
        [...prev, { from: null, to: null, date: moment() }].slice(0, 1),
      );
      return;
    }
  }, [selectedTrip]);

  const buttons = [
    {
      label: 'Round trip',
      type: TripType.ROUND,
    },
    {
      label: 'One-Way',
      type: TripType.SINGLE,
    },
    {
      label: 'Multi city',
      type: TripType.MULTI,
    },
  ];

  const handleDateChange = (start: moment.Moment, end?: moment.Moment) => {
    setSelectedLocations((prev) => {
      return prev.map((previousLocation, index) => {
        if (index === 0) {
          return { ...previousLocation, date: start };
        }

        if (index === 1 && end) {
          return { ...previousLocation, date: end };
        }

        return previousLocation;
      });
    });
  };

  const handleFlightAdd = () => {
    setSelectedLocations((prev) => [
      ...prev,
      {
        from: null,
        to: null,
        date: moment().add(1, 'days'),
      },
    ]);
  };

  const handleSubmit = () => {
    const hasErrors = slicedLocations.some(
      (selectedLocation) =>
        !selectedLocation.date ||
        !selectedLocation.to ||
        !selectedLocation.from,
    );

    if (hasErrors) {
      setDisplayErrors(true);
      return;
    }

    let finalSelectedLocations = [...selectedLocations];
    if (selectedTrip === TripType.ROUND) {
      finalSelectedLocations = [
        { ...selectedLocations[0] },
        {
          ...selectedLocations[1],
          from: selectedLocations[0].to,
          to: selectedLocations[0].from,
        },
      ];
    }

    onSubmit({
      adults: selectedAdults,
      infants: selectedInfants,
      children: selectedChildren,
      class: selectedClass,
      locations: finalSelectedLocations,
      trip: selectedTrip,
    });
  };

  const handleSelectedLocation = (
    from: Airport | null,
    to: Airport | null,
    selectedLocationIndex: number,
  ) => {
    setSelectedLocations((prev) =>
      prev.map((prevSelectedLocation, index) => {
        if (selectedLocationIndex !== index) {
          return prevSelectedLocation;
        }

        return { ...prevSelectedLocation, from, to };
      }),
    );
  };

  const handleSelectedLocationDate = (
    date: moment.Moment,
    selectedLocationIndex: number,
  ) => {
    setSelectedLocations((prev) =>
      prev.map((prevSelectedLocation, index) => {
        if (selectedLocationIndex !== index) {
          return prevSelectedLocation;
        }

        return { ...prevSelectedLocation, date };
      }),
    );
  };

  const getDateValues = () => {
    if (selectedTrip === TripType.ROUND) {
      return selectedLocations.map((selectedLocation) => selectedLocation.date);
    }

    return selectedLocations[0].date;
  };

  const slicedLocations = useMemo(() => {
    if (selectedTrip === TripType.ROUND) {
      return selectedLocations.slice(0, 1);
    }

    return selectedLocations;
  }, [selectedLocations]);

  const removeSelectedLocation = (selectedLocationIndex: number) => {
    setSelectedLocations((prev) =>
      prev.filter((previousLocation, index) => {
        return index !== selectedLocationIndex;
      }),
    );
  };

  return (
    <form className={styles.formContainer}>
      <div className={styles.tripSelector}>
        {buttons.map((button) => (
          <Button
            key={button.type}
            className={cx(styles.tripButton, {
              [styles.active]: selectedTrip === button.type,
            })}
            onClick={() => setSelectedTrip(button.type)}
          >
            {button.label}
          </Button>
        ))}
      </div>
      <div className={styles.inputs}>
        {slicedLocations.map((selectedLocation, index) => (
          <LocationInputs
            key={`location-inputs-${index}`}
            selectedLocation={selectedLocation}
            isDateVisible={selectedTrip === TripType.MULTI}
            airports={airports}
            onSelectedLocation={(from, to) =>
              handleSelectedLocation(from, to, index)
            }
            onSelectedLocationDate={(date) =>
              handleSelectedLocationDate(date, index)
            }
            displayErrors={displayErrors}
            displayRemoveButton={index > 0}
            onRemoveLocation={() => removeSelectedLocation(index)}
          />
        ))}
        <div
          className={cx(styles.bottomInputs, {
            [styles.hiddenDateRange]: selectedTrip === TripType.MULTI,
          })}
        >
          {selectedTrip !== TripType.MULTI && (
            <DateInput
              onChange={handleDateChange}
              values={getDateValues()}
              isSmall={false}
              hasRange={selectedTrip === TripType.ROUND}
              withRemove={false}
            />
          )}
          {selectedTrip === TripType.MULTI && (
            <Button className={styles.addFlight} onClick={handleFlightAdd}>
              Add another flight
            </Button>
          )}
          <PeopleInput
            selectedClass={selectedClass}
            onClassSelected={setSelectedClass}
            adults={selectedAdults}
            childrenCount={selectedChildren}
            infants={selectedInfants}
            onAdultsChange={setSelectedAdults}
            onChildrenChange={setSelectedChildren}
            onInfantsChange={setSelectedInfants}
          />
        </div>
      </div>
      <div className={styles.submitButton}>
        <Button onClick={handleSubmit}>Find Flights</Button>
      </div>
    </form>
  );
};

export default Form;
