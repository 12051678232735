import React from 'react';
import styles from './TravelInfo.module.scss';

type Props = {
  title: string;
  subtitle: string;
};

export const TravelInfo = ({ title, subtitle }: Props) => {
  return (
    <div className={styles.travelInfoContainer}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default TravelInfo;
