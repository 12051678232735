export enum TripType {
  // eslint-disable-next-line no-unused-vars
  ROUND = 'ROUND',
  // eslint-disable-next-line no-unused-vars
  SINGLE = 'SINGLE',
  // eslint-disable-next-line no-unused-vars
  MULTI = 'MULTI',
}

export enum ClassType {
  // eslint-disable-next-line no-unused-vars
  ECONOMY = 'ECONOMY',
  // eslint-disable-next-line no-unused-vars
  PREMIUM = 'PREMIUM',
  // eslint-disable-next-line no-unused-vars
  BUSINESS = 'BUSINESS',
  // eslint-disable-next-line no-unused-vars
  FIRST = 'FIRST',
}

export const CLASS_BUTTONS = [
  {
    type: ClassType.ECONOMY,
    label: 'Economy',
  },
  {
    type: ClassType.PREMIUM,
    label: 'Premium',
  },
  {
    type: ClassType.BUSINESS,
    label: 'Business',
  },
  {
    type: ClassType.FIRST,
    label: 'First',
  },
];

export type Airport = {
  airport: string;
  region: string;
  country: string;
  iata: string;
  icao: string;
};
