import React from 'react';
import styles from './PeopleTypeInput.module.scss';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

type Props = {
  title: string;
  subtitle: string;
  value: number;
  onChange: (changedValue: number) => void;
};

export const PeopleTypeInput = ({
  title,
  subtitle,
  value,
  onChange,
}: Props) => {
  return (
    <div className={styles.peopleSelectContainer}>
      <div className={styles.peopleTypeContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.stepperContainer}>
        <Button
          className={cx(styles.stepperIcon, styles.subtract)}
          disabled={value === 0}
          onClick={() => onChange(value - 1)}
        >
          <FontAwesomeIcon icon={faMinus} fixedWidth />
        </Button>
        <span className={styles.value}>{value}</span>
        <Button
          className={cx(styles.stepperIcon, styles.add)}
          onClick={() => onChange(value + 1)}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth />
        </Button>
      </div>
    </div>
  );
};

export default PeopleTypeInput;
