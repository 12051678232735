import React from 'react';
import DatePicker from 'react-multi-date-picker';
import styles from './DateInput.module.scss';
import { ReactSVG } from 'react-svg';
import calendarIcon from '../../../../asset/menu-board.svg';
import DateObject from 'react-date-object';
import moment from 'moment';
import cx from 'classnames';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';

type Props = {
  onChange: (start: moment.Moment, end?: moment.Moment) => void;
  values: moment.Moment[] | moment.Moment;
  isSmall: boolean;
  hasRange: boolean;
  withRemove: boolean;
};

export const DateInput = ({
  onChange,
  values,
  isSmall,
  hasRange,
  withRemove,
}: Props) => {
  const handleChange = (date: DateObject | DateObject[] | null) => {
    if (!hasRange && !Array.isArray(date)) {
      onChange(moment(date?.toDate()));
    }

    if (!Array.isArray(date)) {
      return;
    }

    const values = date.map((value) => value.toDate());

    if (values.length === 2) {
      onChange(moment(values[0]), moment(values[1]));
    }
  };

  const { width } = useWindowSize();

  const getNumberOfMonths = () => {
    return width && width >= 900 ? 2 : 1;
  };

  return (
    <div
      className={cx(styles.dateInputContainer, {
        [styles.single]: isSmall,
        [styles.withRemove]: withRemove,
      })}
    >
      <DatePicker
        range={hasRange}
        multiple={false}
        numberOfMonths={getNumberOfMonths()}
        disableMonthPicker
        disableYearPicker
        dateSeparator="  |  "
        format={'ddd, MMM D'}
        containerClassName={styles.dateInput}
        editable={false}
        formattingIgnoreList={[',']}
        monthYearSeparator={' '}
        minDate={new Date()}
        onChange={handleChange}
        value={
          Array.isArray(values)
            ? values.map((value) => value.toDate())
            : values.toDate()
        }
      />
      <ReactSVG src={calendarIcon} className={styles.calendarIcon} />
    </div>
  );
};

export default DateInput;
