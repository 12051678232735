import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

const target = document.getElementById('erw-root') as HTMLElement;

if (target) {
  const root = ReactDOM.createRoot(target);
  root.render(<App />);
}

reportWebVitals();
