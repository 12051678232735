import React, { useState } from 'react';
import styles from './PeopleInput.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import PeopleTypeInput from './PeopleTypeInput/PeopleTypeInput';
import { Button } from '@mui/material';
import cx from 'classnames';
import { CLASS_BUTTONS, ClassType } from '../../../../constants/constants';

type Props = {
  selectedClass: ClassType;
  onClassSelected: (selectedClass: ClassType) => void;
  adults: number;
  childrenCount: number;
  infants: number;
  onAdultsChange: (count: number) => void;
  onChildrenChange: (count: number) => void;
  onInfantsChange: (count: number) => void;
};

export const PeopleInput = ({
  selectedClass,
  onClassSelected,
  adults,
  childrenCount,
  infants,
  onAdultsChange,
  onChildrenChange,
  onInfantsChange,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getLabel = () => {
    const totalTravelers = adults + childrenCount + infants;
    const classLabel =
      CLASS_BUTTONS.find((classButton) => classButton.type === selectedClass)
        ?.label ?? '';

    return `${totalTravelers} Traveler${
      totalTravelers > 1 ? 's' : ''
    }, ${classLabel}`;
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsExpanded(false)}>
      <div className={styles.peopleInputContainer}>
        <div
          className={styles.title}
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {getLabel()}
        </div>
        {isExpanded && (
          <div className={styles.expandedOptions}>
            <div className={styles.expandedInner}>
              <PeopleTypeInput
                title="Adults"
                subtitle="11+ years"
                value={adults}
                onChange={onAdultsChange}
              />
              <PeopleTypeInput
                title="Children"
                subtitle="1 - 11 years"
                value={childrenCount}
                onChange={onChildrenChange}
              />
              <PeopleTypeInput
                title="Infants"
                subtitle="0 - 2 years"
                value={infants}
                onChange={onInfantsChange}
              />
            </div>
            <div className={styles.classSelectContainer}>
              {CLASS_BUTTONS.map((classButton) => (
                <Button
                  key={classButton.type}
                  className={cx(styles.classSelect, {
                    [styles.isSelected]: classButton.type === selectedClass,
                  })}
                  onClick={() => onClassSelected(classButton.type)}
                >
                  {classButton.label}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default PeopleInput;
