import React from 'react';
import styles from './LocationInputs.module.scss';
import LocationSelect from './LocationSelect/LocationSelect';
import { ReactSVG } from 'react-svg';
import swapIcon from '../../../../asset/arrow-swap-horizontal.svg';
import DateInput from '../DateInput/DateInput';
import { SelectedLocation } from '../Form';
import { Airport } from '../../../../constants/constants';
import moment from 'moment';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';

type Props = {
  selectedLocation: SelectedLocation;
  isDateVisible: boolean;
  airports: Airport[];
  onSelectedLocation: (from: Airport | null, to: Airport | null) => void;
  displayErrors: boolean;
  onSelectedLocationDate: (date: moment.Moment) => void;
  onRemoveLocation: () => void;
  displayRemoveButton: boolean;
};

export const LocationInputs = ({
  selectedLocation,
  isDateVisible,
  airports,
  onSelectedLocation,
  displayErrors,
  onSelectedLocationDate,
  onRemoveLocation,
  displayRemoveButton,
}: Props) => {
  const handleFromSelect = (from: Airport) => {
    onSelectedLocation(from, selectedLocation.to);
  };

  const handleToSelect = (to: Airport) => {
    onSelectedLocation(selectedLocation.from, to);
  };

  const handleSwap = () => {
    onSelectedLocation(selectedLocation.to, selectedLocation.from);
  };

  const { width } = useWindowSize();

  return (
    <div className={styles.locationsWithDateContainer}>
      <div className={styles.locationsContainer}>
        <LocationSelect
          airports={airports}
          title="Leaving from"
          onSelectAirport={handleFromSelect}
          selectedAirport={selectedLocation.from}
          displayErrors={displayErrors}
        />
        <ReactSVG
          src={swapIcon}
          className={styles.swapIcon}
          onClick={handleSwap}
        />
        <LocationSelect
          airports={airports}
          title="Going to"
          onSelectAirport={handleToSelect}
          selectedAirport={selectedLocation.to}
          displayErrors={displayErrors}
          isLast
        />
      </div>
      {isDateVisible && (
        <div className={styles.dateContainer}>
          <DateInput
            onChange={(start) => onSelectedLocationDate(start)}
            values={selectedLocation.date}
            isSmall
            withRemove={displayRemoveButton}
            hasRange={false}
          />
          {displayRemoveButton && (
            <div
              className={styles.removeItem}
              onClick={() => onRemoveLocation()}
            >
              –
            </div>
          )}
          {width && width >= 900 && !displayRemoveButton && (
            <div className={styles.emptyPlaceholder}>&nbsp;</div>
          )}
        </div>
      )}
    </div>
  );
};

export default LocationInputs;
