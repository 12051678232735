import React, { ChangeEvent, useMemo, useState, KeyboardEvent } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './LocationSelect.module.scss';
import cx from 'classnames';
import { Airport } from '../../../../../constants/constants';

type Props = {
  airports: Airport[];
  title: string;
  onSelectAirport: (airport: Airport) => void;
  selectedAirport: Airport | null;
  displayErrors: boolean;
  isLast?: boolean;
};

export const LocationSelect = ({
  airports,
  title,
  onSelectAirport,
  selectedAirport,
  displayErrors,
  isLast,
}: Props) => {
  const [value, setValue] = useState<string>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const filteredAirports = useMemo(() => {
    return airports.filter(
      (airport) =>
        airport.airport?.toLowerCase().includes(value?.toLowerCase()) ||
        airport.region?.toLowerCase().includes(value?.toLowerCase()) ||
        airport.country?.toLowerCase().includes(value?.toLowerCase()) ||
        airport.iata?.toLowerCase().includes(value?.toLowerCase()),
    );
  }, [value, airports]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setValue(event.target.value);
  };

  const handleAirportSelect = (airport: Airport) => {
    onSelectAirport(airport);
    setValue(airport.region);

    setTimeout(() => {
      setIsFocus(false);
    });
  };

  const hasErrors = useMemo(() => {
    if (!displayErrors) {
      return;
    }

    return !selectedAirport;
  }, [displayErrors, selectedAirport]);

  const handleEnterClick = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') {
      return;
    }

    if (filteredAirports.length > 0) {
      handleAirportSelect(filteredAirports[0]);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsFocus(false)}>
      <div
        className={cx(styles.locationInputContainer, {
          [styles.error]: hasErrors,
        })}
        onClick={() => {
          setIsFocus(true);
        }}
      >
        <label
          className={cx(styles.locationLabel, {
            [styles.isFocused]: isFocus || value,
          })}
        >
          {title}
        </label>
        {!isFocus && selectedAirport && (
          <div className={styles.selectedAirport}>
            <span className={styles.cityName}>{selectedAirport?.region}</span>
            <span className={styles.airportCode}>
              {selectedAirport?.iata ?? selectedAirport?.icao}
            </span>
          </div>
        )}
        {isFocus && (
          <>
            <input
              className={styles.input}
              autoFocus
              onChange={handleInputChange}
              value={value}
              onKeyDown={(e) => handleEnterClick(e)}
            />
            {value.length > 0 && (
              <div
                className={cx(styles.locationSelection, {
                  [styles.last]: isLast,
                })}
              >
                {filteredAirports.slice(0, 100).map((airport, index) => (
                  <div
                    key={`airport-${airport.icao}`}
                    className={styles.airportContainer}
                    onClick={() => handleAirportSelect(airport)}
                  >
                    <div className={styles.locationContainer}>
                      <div className={styles.locationTitle}>
                        {airport.region}, {airport.country}
                      </div>
                      <div className={styles.airportName}>
                        {airport.airport}
                      </div>
                    </div>
                    <div className={styles.code}>
                      {airport.iata ?? airport.icao}
                    </div>
                  </div>
                ))}
                {filteredAirports.length >
                  filteredAirports.slice(0, 100).length && (
                  <div className={styles.airportContainer}>
                    <div className={styles.moreAirports}>
                      {filteredAirports.length -
                        filteredAirports.slice(0, 100).length}{' '}
                      More...
                    </div>
                  </div>
                )}
                {filteredAirports.length === 0 && (
                  <div className={styles.airportContainer}>
                    <div className={styles.moreAirports}>No results found</div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default LocationSelect;
