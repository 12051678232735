import React, { useMemo, useState } from 'react';
import styles from './Summary.module.scss';
import { SelectedValues } from '../MainContainer';
import SelectedLocation from './SelectedLocation/SelectedLocation';
import TravelInfo from './TravelInfo/TravelInfo';
import { pluralize } from '../../../utils/pluralize';
import { Airport, CLASS_BUTTONS } from '../../../constants/constants';
import { Button } from '@mui/material';
import ContactForm from './ContactForm/ContactForm';
import emailjs from 'emailjs-com';
import { SERVICE_ID, TEMPLATE_ID, USER_ID } from '../../../constants/emailJS';
import _ from 'lodash';

type Props = {
  selectedValues: SelectedValues;
  handleBackClick: () => void;
};

export type ContactFormInputs = {
  name: string;
  email: string;
  phone: string;
  methods: string[];
};

type FormData = {
  name: string;
  email: string;
  phone: string;
  methods: string;
  locations: string;
  adults: number;
  children: number;
  infants: number;
  class: string;
};

export const Summary = ({ selectedValues, handleBackClick }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const passengersSubtitle = useMemo(() => {
    const results = [];

    if (selectedValues.adults > 0) {
      results.push(`${pluralize(selectedValues.adults, 'Adult')}`);
    }

    if (selectedValues.children > 0) {
      results.push(`${pluralize(selectedValues.children, 'Child', 'ren')}`);
    }

    if (selectedValues.infants > 0) {
      results.push(`${pluralize(selectedValues.infants, 'Infant')}`);
    }

    return results.join(', ');
  }, [selectedValues]);

  const classSubtitle = useMemo(() => {
    return (
      CLASS_BUTTONS.find(
        (classButton) => classButton.type === selectedValues.class,
      )?.label ?? ''
    );
  }, [selectedValues]);

  const formatAirport = (airport: Airport | null) => {
    if (!airport) {
      return '';
    }

    return `${airport.region}, ${airport.country}, ${
      airport.iata ?? airport.icao
    }`;
  };

  const handleContactFormSubmit = (inputs: ContactFormInputs) => {
    sendEmail({
      adults: selectedValues.adults,
      email: inputs.email,
      methods: inputs.methods
        .map((method) => _.capitalize(method.replaceAll('_', ' ')))
        .join(', '),
      children: selectedValues.children,
      class: _.capitalize(selectedValues.class.toLowerCase()),
      name: inputs.name,
      phone: inputs.phone,
      infants: selectedValues.infants,
      locations: selectedValues.locations
        .map(
          (selectedLocation) =>
            `${formatAirport(selectedLocation.from)} - ${formatAirport(
              selectedLocation.to,
            )}, ${selectedLocation.date.format('ddd, MMM D YYYY')}`,
        )
        .join('\n'),
    });
  };

  const sendEmail = (formData: FormData) => {
    emailjs.init(USER_ID);
    setIsSuccess(false);
    setIsLoading(true);

    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData).then(
      () => {
        setIsSuccess(true);
      },
      () => {
        setIsError(true);
      },
    );
  };

  return (
    <div className={styles.summaryContainer}>
      <div className={styles.summary}>
        <div className={styles.selectedLocations}>
          {selectedValues.locations.map((selectedLocation, index) => (
            <SelectedLocation
              key={`selected-location-${index}`}
              selectedLocation={selectedLocation}
            />
          ))}
        </div>
        <div className={styles.travelType}>
          <TravelInfo title="Passengers" subtitle={passengersSubtitle} />
          <TravelInfo title="Class" subtitle={classSubtitle} />
          <div className={styles.backButton}>
            <Button onClick={handleBackClick}>Change Search</Button>
          </div>
        </div>
      </div>
      <div className={styles.formSubmitContainer}>
        <div className={styles.upperPart}>
          <div className={styles.contextContainer}>
            <div className={styles.title}>
              Leave your details to get a quote
            </div>
            <div className={styles.subtitle}>
              In order to secure the lowest fare, simply enter your information
              on the form below and one of our specialists will contact you
            </div>
          </div>
          <div className={styles.contextContainer}>
            <div className={styles.title}>Or give us a call</div>
            <a href="tel:02085028300" className={styles.phoneNumber}>
              020 8502 8300
            </a>
          </div>
        </div>
        <div className={styles.downPart}>
          <ContactForm
            onSubmit={handleContactFormSubmit}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            onBackClick={handleBackClick}
          />
          <div className={styles.subtitle}>
            **By submitting my details I agree to be contacted to discuss my
            travel plans. <a href="https://google.com">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
