import React, { useEffect, useState } from 'react';
import styles from './MainContainer.module.scss';
import Form, { SelectedLocation } from './Form/Form';
import Summary from './Summary/Summary';
import { Airport, ClassType, TripType } from '../../constants/constants';

export enum FormStep {
  // eslint-disable-next-line no-unused-vars
  FIRST = 'FIRST',
  // eslint-disable-next-line no-unused-vars
  SECOND = 'SECOND',
}

export type SelectedValues = {
  locations: SelectedLocation[];
  adults: number;
  children: number;
  infants: number;
  class: ClassType;
  trip: TripType;
};

export const MainContainer = () => {
  const [currentStep, setCurrentStep] = useState<FormStep>(FormStep.FIRST);
  const [selectedValues, setSelectedValues] = useState<SelectedValues | null>(
    null,
  );
  const [airports, setAirports] = useState<Airport[]>([]);

  const handleSubmit = (submitForm: SelectedValues) => {
    setSelectedValues(submitForm);
    setCurrentStep(FormStep.SECOND);
  };

  useEffect(() => {
    getAirports();
  }, []);

  const getAirports = async () => {
    const response = await fetch(
      '/wp-content/plugins/react-wordpress/widget/build/airports.json',
    );
    const data = (await response.json()) as Airport[];

    setAirports(
      data.filter((airport) => {
        if (!airport.iata) {
          return false;
        }

        if (airport.airport?.toLowerCase().includes('base')) {
          return false;
        }

        if (airport.airport?.toLowerCase().includes('heliport')) {
          return false;
        }

        if (airport.airport?.toLowerCase().includes('aerodrome')) {
          return false;
        }

        if (airport.airport?.includes('RAF')) {
          return false;
        }

        if (
          airport.airport?.toLowerCase().includes('london') ||
          airport.region?.toLowerCase().includes('london')
        ) {
          return ['LTN', 'STN', 'LHR', 'LGW', 'LCY'].includes(airport.iata);
        }

        return true;
      }),
    );
  };

  return (
    <div className={styles.blurContainer}>
      {currentStep === FormStep.FIRST && (
        <Form
          onSubmit={handleSubmit}
          airports={airports}
          selectedValues={selectedValues}
        />
      )}
      {currentStep === FormStep.SECOND && selectedValues && (
        <Summary
          selectedValues={selectedValues}
          handleBackClick={() => setCurrentStep(FormStep.FIRST)}
        />
      )}
    </div>
  );
};

export default MainContainer;
